import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PreSettings, Param } from '../../../../functions/src/shared/setting-model';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Tools } from '../tools';
import { GlobalConstants } from 'functions/src/shared/global-constants';


const GLOBAL = new GlobalConstants();

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  // private _paramsArray: { [name: string]: any[] } = {};
  private _paramsObj: { [name: string]: any } = {};
  private _paramsSelect: { [name: string]: { id: string, name: string, icon?: string, photo?: string }[] } = {};
  private _preSettings: PreSettings = new PreSettings();
  private _params$: { [name: string]: Observable<any> } = {};
  public langs = GLOBAL.LANGS;
  private _exRates: any = {};
  public mainLang = 'en';
  public version: string = null;
  public realtorsAgency: any = {};
  //for dyn select
  public show = false;
  public tools = new Tools();

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
  ) {
    this._paramsSelect.tmp = [];
  }


  public async initAllParams(): Promise<any> {
    if (this.auth.loggedIn) {
      // Init All Params
      for (let paramKey in this._preSettings.params) {
        this._initParam(this._preSettings.params[paramKey]);
      }
      // Init User Agencies
      await this._initAgencies();
      // Init List of Specific Users
      await this._initUsers();
      // Init List of Sectors from Regions
      this._initSectors();
      //Init ExRates
      this._initExRates();

    }
  }

  private _initParam(param: Param): Promise<any> {
    // this._paramsArray[param.$key] = [];
    this._paramsObj[param.$key] = {};
    this._paramsSelect[param.$key] = [];
    let w = this;

    if (param.db) {
      w._params$[param.$key] = this.colWithIds(`/settings/parameters/${param.$key}`, ref => { let q = ref.where('archive', '==', false); q = q.orderBy('name'); return q });
      return firebase.firestore().collection(`/settings/parameters/${param.$key}`).where('archive', '==', false).orderBy('name').get().then(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          const paramValue = Object.assign({ id: doc.id }, doc.data())
          // w._paramsArray[param.$key].push(paramValue);
          w._paramsObj[param.$key][doc.id] = paramValue;
          // Manage multiLang Parameters
          w._paramsSelect[param.$key].push({ id: doc.id, name: (param.multiLang ? paramValue.name[w.mainLang] : paramValue.name) });
        });
      });
    } else {
      w._paramsObj[param.$key] = param.values;
      // Manage multiLang Parameters
      for (const valueKey in param.values) {
        w._paramsSelect[param.$key].push({ id: valueKey, name: (param.multiLang ? param.values[valueKey].name[w.mainLang] : param.values[valueKey].name), icon: param.values[valueKey].icon ? param.values[valueKey].icon : null });
      }
    }
  }

  public async getVersion(): Promise<any> {
    return firebase.firestore().doc(`/settings/CRM`).get().then(snapshot => {
      if (snapshot.exists) {
        this.version = snapshot.data().version;
        return this.version;
      }
      return null;
    });
  }


  private async _initAgencies() {

    // TODO: Change ID system...
    let w = this;
    w._paramsObj.agencies = {};
    w._paramsSelect.agencies = [];

    await firebase.firestore().collection(`/agencies`).orderBy('name').get().then(querySnapshot => {
      querySnapshot.forEach(function (doc) {
        const agency = doc.data();
        w._paramsObj.agencies[agency.id] = agency
        w._paramsSelect.agencies.push({ id: agency.id, name: agency.name });
      });
    });

    this._paramsObj.userAgencies = {};
    this._paramsSelect.userAgencies = [];

    for (const agencyID of this.auth.profile.values.agencies) {
      if (this._paramsObj.agencies[agencyID]) {
        this._paramsObj.userAgencies[agencyID] = this._paramsObj.agencies[agencyID];
        this._paramsSelect.userAgencies.push({ id: agencyID, name: this._paramsObj.agencies[agencyID].name });
      }
    }

    //Init realtorsAgency in order 
    for (const agencyKey in this._paramsObj.agencies) {
      this.realtorsAgency[agencyKey] = [];
    }
  }

  private async _initUsers(): Promise<any> {
    // this._paramsArray[param.$key] = [];
    let w = this;
    w._paramsObj.users = {};
    w._paramsSelect.users = [];
    w._paramsObj.realtors = {};
    w._paramsSelect.realtors = [];
    w._paramsObj.allUsers = {};
    w._paramsSelect.allUsers = [];

    return firebase.firestore().collection(`/users`).orderBy('fullname').get().then(querySnapshot => {
      const activeUsers = [];
      const inactiveUsers = [];
      const activeRealtors = [];
      const inactiveRealtors = [];
      const activeRealtorsAgency = {};
      const inactiveRealtorsAgency = {};

      querySnapshot.forEach(function (doc) {
      const user = Object.assign({ id: doc.id }, doc.data());
      const userName = user.status === 'active' ? user.fullname : `${user.fullname} (OUT)`;
      w._paramsObj.allUsers[doc.id] = Object.assign({ name: userName }, user);

      if (user.status === 'active') {
        activeUsers.push({ id: doc.id, name: userName, photo: user.extPhoto });
      } else {
        inactiveUsers.push({ id: doc.id, name: userName, photo: user.extPhoto });
      }

      // Only Active Users
      if (user.status === 'active') {
        w._paramsObj.users[doc.id] = Object.assign({ name: user.fullname }, user);
        w._paramsSelect.users.push({ id: doc.id, name: user.fullname, photo: user.extPhoto });
      }

      // Only Realtors
      if (user.role === 'realtor' || user.role === 'director' || user.role === 'admin') {
        if (user.status === 'active') {
        activeRealtors.push({ id: doc.id, name: user.fullname, photo: user.extPhoto });
        } else {
        inactiveRealtors.push({ id: doc.id, name: userName, photo: user.extPhoto });
        }

        for (const agencyKey of user.agencies) {
        if (!activeRealtorsAgency[agencyKey]) {
          activeRealtorsAgency[agencyKey] = [];
        }
        if (!inactiveRealtorsAgency[agencyKey]) {
          inactiveRealtorsAgency[agencyKey] = [];
        }
        if (user.status === 'active') {
          activeRealtorsAgency[agencyKey].push({ id: doc.id, name: user.fullname, photo: user.extPhoto });
        } else {
          inactiveRealtorsAgency[agencyKey].push({ id: doc.id, name: userName, photo: user.extPhoto });
        }
        }
      }
      });

      // Combine active and inactive users, with inactive users at the end
      w._paramsSelect.allUsers = [...activeUsers, ...inactiveUsers];
      w._paramsSelect.realtors = [...activeRealtors, ...inactiveRealtors];

      for (const agencyKey in activeRealtorsAgency) {
      w.realtorsAgency[agencyKey] = [...activeRealtorsAgency[agencyKey], ...inactiveRealtorsAgency[agencyKey]];
      }
    });

  }

  getDefaultAgency(realtor: string): null | string {
    if (this.users[realtor].agency) {
      return this.users[realtor].agency;
    }
    if (this.users[realtor].agencies && this.users[realtor].agencies.length) {
      return this.users[realtor].agencies[0];
    }
    return null;
  }

  public setDynAgencies(realtor: string) {

    this._paramsSelect.tmp = [];
    if (realtor && this.users[realtor]) {
      for (const agencySel of this._paramsSelect.agencies) {
        if (this.users[realtor].agencies.includes(agencySel.id)) {
          this._paramsSelect.tmp.push(agencySel);
        }
      }
    }

  }

  // private _sameAgency(user1: any, user2: any): boolean {
  //   if (user1.agencies && user1.agencies.length && user2.agencies && user2.agencies.length) {
  //     for (const agency of user1.agencies) {
  //       if (user2.agencies.includes(agency)) {
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // }

  private _sameSubRole(user1: any, user2: any): boolean {
    if (user1.subRoles && user1.subRoles.length && user2.subRoles && user2.subRoles.length) {
      for (const role of user1.subRoles) {
        if (user2.subRoles.includes(role)) {
          return true;
        }
      }
    }
    return false;
  }

  private _initExRates(): Promise<any> {
    let w = this;

    return firebase.firestore().doc(`/settings/exrates`).get().then(snapshot => {
      w._exRates = snapshot.data();
    });

  }

  get settings() {
    return this._preSettings.params;
  }

  get regions() {
    return this._preSettings.regions;
  }


  get users() {
    return this._paramsObj.users;
  }

  get exRates(): any {
    return this._exRates;
  }

  private _initSectors() {
    this._paramsObj.sectors = {};
    this._paramsSelect.sectors = [];

    for (const sector of this._preSettings.regions.sectors) {
      this._paramsObj.sectors[sector.name] = { name: sector.title }
      this._paramsSelect.sectors.push({ id: sector.name, name: sector.title });
    }
  }
  // getParamsArray(param: string) {
  //   return this._paramsArray[param];
  // }

  getParamsObj(param: string) {
    return this._paramsObj[param];
  }

  getParamsSelect(param: string): { id: string, name: string, icon?: string }[] {
    return this._paramsSelect[param];
  }

  getParams$(param: string) {
    return this._params$[param];
  }

  getParamValue(param: string, key: string) {
    return this._paramsObj[param][key];
  }

  getParamValueName(param: string, key: string): string {
    if (this._paramsObj[param][key] && this._paramsObj[param][key].name) {
      if (this.settings[param] && this.settings[param].multiLang) {
        return this._paramsObj[param][key].name[this.mainLang];
      }
      return this._paramsObj[param][key].name;

    }
    return '';
  }

  getParamIcon(param: string, key: string): string {
    if (key && this._paramsObj[param][key] && this._paramsObj[param][key].icon) {
      return this._paramsObj[param][key].icon;
    }
    return '';
  }

  getUserPhoto(key: string): string {
    if (key && this._paramsObj.allUsers[key] && this._paramsObj.allUsers[key].extPhoto) {
      return this._paramsObj.allUsers[key].extPhoto;
    }
    return null;
  }

  getUserName(key: string): string {
    if (key && this._paramsObj.allUsers[key] && this._paramsObj.allUsers[key].fullname) {
      return this._paramsObj.allUsers[key].fullname;
    }
    return '';
  }

  getParamValueLang(param: string, key: string, lang: string) {
    return this._paramsObj[param][key].name[lang];
  }

  get uid() {
    return this.auth.uid;
  }

  addNewParam(param: string, newItem: any): Promise<any> {
    if (this.auth.loggedIn) {
      newItem.archive = false;
      return this.afs.collection(`/settings/parameters/${param}`).add(newItem).then(() => {
        this._initParam(this.settings[param]);
      });
    }
  }

  saveParam(param: string, paramKey: string, savedItem: any): Promise<any> {
    if (this.auth.loggedIn) {
      return this.afs.doc(`/settings/parameters/${param}/${paramKey}`).set(savedItem, { merge: true });
    }
  }

  archiveParam(param: string, $key: string): Promise<any> {
    if (this.auth.loggedIn) {
      return this.afs.doc(`/settings/parameters/${param}/${$key}`).update({ archive: true }).then(() => {
      });
    }
  }

  protected colWithIds(path: string, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          return Object.assign({ $key: a.payload.doc.id }, a.payload.doc.data());
        });
      })
    );
  }
}


